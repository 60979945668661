<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
      v-model="query.priceType"
      clearable
      placeholder="支付类型"
      class="filter-item"
      style="width: 130px"
      @change="toQuery"
    >
      <el-option
        v-for="item in queryPriceTypeOptions"
        :key="item.key"
        :label="item.display_name"
        :value="item.key"
      />
    </el-select>
    <el-select
      v-model="query.status"
      clearable
      placeholder="支付状态"
      class="filter-item"
      style="width: 130px"
      @change="toQuery"
    >
      <el-option
        v-for="item in queryStatusOptions"
        :key="item.key"
        :label="item.display_name"
        :value="item.key"
      />
    </el-select>
    <el-input
      v-model="query.signalInfo"
      clearable
      placeholder="收款账户昵称/MT4"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-input v-model="query.copyInfo" clearable placeholder="付款账户昵称/MT4" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    &nbsp;&nbsp;
    <div class="filter-item">
      支付时间:
      <el-date-picker style="width: 150px;" v-model="query.startTime" type="date" placeholder="开始时间"></el-date-picker>
      至
      <el-date-picker style="width: 150px;" v-model="query.endTime" type="date" placeholder="结束时间"></el-date-picker>
    </div>
    &nbsp;&nbsp;
    <el-button v-if="checkPermission(['ADMIN','SUBSCRIPTION_ALL','SUBSCRIPTION_SELECT'])" class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" type="primary" size="mini" @click="toClearInit">清空</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import { parseTime } from '@/utils/index'
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      downloadLoading: false,
      queryStatusOptions: [
        { key: '0', display_name: '处理中' },
        { key: '1', display_name: '成功' },
        { key: '2', display_name: '失败'},
        { key: '3', display_name: '待处理' }
      ],
      queryPriceTypeOptions: [
        { key: '0', display_name: '订阅费' },
        { key: '1', display_name: '管理费' },
        { key: '2', display_name: '表现费'}
      ],
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery')
    },
    toClearInit(){
      this.$emit('clear');
    },
    copyLoginHandle(){
      this.$emit('copyLoginHandle');
    },
    signalLoginEnterHandle(){
      this.$emit('signalLoginEnterHandle');
    },
  }
}
</script>
