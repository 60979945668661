<template>
  <div class="app-container summary-page">
    <eHeader
      ref="header"
      :query="query"
      @copyLoginHandle="copyLoginHandle"
      @signalLoginEnterHandle="signalLoginEnterHandle"
      @toQuery="toQuery"
      @clear="toClearInit"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="id" label="流水号"/>
      <el-table-column prop="login" label="付款账户">
        <template slot-scope="scope">
          <span class="span-item">{{scope.row.copyAlias}}</span>
          <span class="span-item">
            {{scope.row.copyLogin}}
            <span class="" v-if="scope.row.sourceLogin">({{scope.row.sourceLogin}}代付)</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="signalLogin" label="收款账户">
        <template slot-scope="scope">
          <span class="span-item">{{scope.row.signalAlias}}</span>
          <span class="span-item">
            {{scope.row.signalLogin	}}
            <span class="" v-if="scope.row.targetLogin">({{scope.row.targetLogin}}代收)</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="priceType" label="支付类型">
        <template slot-scope="scope">
          <span >{{scope.row.priceType==0?"订阅费":scope.row.priceType==1?"管理费":"表现费"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="支付金额">
        <template slot-scope="scope">
          <span v-if="scope.row.priceType==0">{{scope.row.price}}</span>
          <el-tooltip v-else class="item" effect="dark" :content="scope.row.priceDetail" placement="top-start">
             <span>{{scope.row.price}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="支付状态">
        <template slot-scope="scope">
          <span >{{scope.row.status==0?"处理中":scope.row.status==1?"成功":scope.row.status==2?"失败":"待处理"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="支付说明"/>
      <el-table-column prop="transDate" label="支付时间">
        <template slot-scope="scope">
          <span>{{scope.row.transDate}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="230" :show-overflow-tooltip="true"/>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
  import { limitNumber } from '@/utils/validate'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/copy/subfinance/header'
export default {
  name:'subfin',
  components: { eHeader },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      dialogFormVisible:false,
      flatformdata:[],
      statistics:{
        priceSum: ''
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    })
  },
  methods: {
    parseTime,
    beforeInit() {
      this.url = '/crm/querySubPayTransList'
      const sort = 'transDate,desc'
      const query = this.query;
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
      }
      const priceType = query.priceType;
      const status = query.status;

      const startTime = query.startTime;
      const endTime = query.endTime;
      if (priceType !== "" && priceType !== null) {
        this.params["priceType"] = priceType;
      }
      if (status !== "" && status !== null) {
        this.params["status"] = status;
      }
      if (this.query.copyInfo !== "" && this.query.copyInfo !== null) {
        this.params["copyInfo"] = this.query.copyInfo;
      }
      if (this.query.signalInfo !== "" && this.query.signalInfo !== null) {
        this.params["signalInfo"] = this.query.signalInfo;
      }
      if (startTime !== undefined && startTime !== "" && startTime !== null) {
        this.params["startTime"] = parseTime(startTime);
      }
      if (endTime !== undefined && endTime !== "" && endTime !== null){
        this.params["endTime"] = parseTime(endTime);
      }
      return true
    },
    toClearInit() {
      this.query = {}
      this.init();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (index) {
          case 0:
            sums[index] = '合计';
            break;
          case 4:
            sums[index] = `${this.statistics.priceSum}`;
            break;
        }
      });

      return sums;
    },
    copyLoginHandle(){
      this.query.signalLogin = limitNumber(this.query.signalLogin);
      this.toQuery();
    },
    signalLoginEnterHandle(){
      this.query.copyLogin = limitNumber(this.query.copyLogin);
      this.toQuery();
    },
  }
}
</script>

<style scoped>
.link-sum{
  color:blue;
  text-decoration:underline;
  cursor:pointer;
}
.link-sum:hover {
  text-decoration:underline;
   color:red;
}
.span-item{
  display: block;
}
</style>

